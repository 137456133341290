.carausel-custom {
  height: 750px;
}

.carauselHeader {
  background: radial-gradient(
    circle at -3.1% -4.3%,
    rgb(57, 62, 70) 0%,
    rgb(255, 211, 105) 90%
  );
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 22px;
  letter-spacing: 2px;
  color: #fff;
}

.carauselParagraph {
  display: inline-block;
  background: rgb(57, 62, 70) 0%;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 14px;
  color: #fff;
  margin-bottom: 0px;
}

.carousel-indicators {
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 700px) {
  .carausel-custom {
    height: 450px;
  }
  .carauselHeader {
    padding: 0.5rem 1rem;
    font-size: 16px;
    letter-spacing: 2px;
    color: #fff;
  }
}
