.table-wrapper {
    width: 100%;
  }
  
  .table {
    display: block;
    overflow: hidden;
    table-layout: fixed;
    border-collapse: collapse;
    box-shadow: 0px 10px 10px #ccc;
    border-radius: 10px;
    white-space: nowrap;
    width: 100%;
    max-width: 80%;
    margin: auto;
    table-layout: auto;
    overflow-x: auto;
  }
  
  .table thead {
    background-color: #ccc;
    color: #222;
  }
  
  .table th,
  .table td {
    padding: 0.8rem;
  }
  
  .table td {
    border-top: 0.5px solid #ddd;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .table tbody tr:hover {
    background-color: #eee;
  }
  
  .expand {
    width: 100%;
  }
  
  .label {
    border-radius: 3px;
    padding: 0.3rem;
    color: white;
  }
  
  .label-draft {
    background-color: #777;
  }
  
  .label-true {
    background-color: #42a942;
  }
  
  .label-false {
    background-color: #d9534f;
  }
  
  .actions {
    display: flex;
    justify-content: space-around;
  }
  
  .actions svg {
    cursor: pointer;
  }
  
  .delete-btn {
    color: #e10d05;
  }