.featuredProjectsContainer {
    padding-top: 10px;
    padding-bottom: 90px;
}

#app {
    height: 100%x;
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.swiper {
    width: 100%;
    height: 400px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
}



.intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
}

.intro p {
    margin-bottom: 0;
}

.people {
    padding: 50px 0;
    cursor: pointer;
}

.item {
    margin-bottom: 30px;
}

.item .box {
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 6px;
    height: 280px;
    position: relative;
    overflow: hidden;
}

.item .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #393E46;
    transition: opacity 0.15s ease-in;
    opacity: 0;
    padding-top: 80px;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.item:hover .cover {
    opacity: 0.8;
}

.item .name {
    font-weight: bold;
}

.item .title {
    text-transform: uppercase;
    font-weight: bold;
    color: #bbd8fb;
    letter-spacing: 2px;
    font-size: 13px;
    margin-bottom: 20px;
}

.social {
    font-size: 18px;
}

.social a {
    color: inherit;
    margin: 0 10px;
    display: inline-block;
    opacity: 0.7;
}

.social a:hover {
    opacity: 1;
}

.heading {
    text-align: center;
    padding-bottom: 2rem;
    font-size: 2rem;
    color: #3b3b3b;
}

.heading span {
    position: relative;
    z-index: 0;
}

.heading span::before {
    content: "";
    position: absolute;
    bottom: .1rem;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: #FFD369;
    clip-path: polygon(0 90%, 100% 80%, 100% 100%, 0% 100%);
}