.servicesContainer {
    padding-top: 90px;
    padding-bottom: 90px;
}

.serviceTitle {
    display: block;
    margin: 0px 0 50px;
    text-align: center;
}

.serviceTitle h3 {
    display: inline-block;
    margin-top: 0px;
    letter-spacing: 1px;
    padding-bottom: 10px;
    line-height: 40px;
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    border-bottom: 4px solid #FFD369;
    color: #3b3b3b;
}

.serviceIcon {
    margin-left: auto;
    margin-right: auto;
    width: 90px;
    height: 90px;
    display: block;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    text-align: center;
    -webkit-transition: all ease 0.8s;
    -moz-transition: all ease 0.8s;
    transition: all ease 0.8s;
    border: 4px solid #FFD369;
    background-color: #FFD369;
    background-size: 100% 200%;
}

.serviceIcon span {
    color: #fff;
    font-size: 50px;
    position: relative;
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 150%;
    -webkit-transition: all 0.25s linear;
    -moz-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    transition: all 0.25s linear;
}

.serviceArea {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 5px;
}

.serviceArea:hover .serviceIcon span {
    color: #FFD369 ;
}

.serviceArea:hover .serviceIcon {
    background-color: #ffffff;
}

.serviceDetail h3 {
    font-size: 24px;
    letter-spacing: normal;
    line-height: 32px;
    word-wrap: break-word;
    margin: 0 0 10px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    text-align: left;
}

.serviceDetail p {
    font-size: 16px;
    line-height: 27px;
    text-align: justify;
}

.hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
  }
  
  .hovereffect .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 5%;
    left: 0;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  
  .hovereffect:hover .overlay {
    background-color: rgba(170,170,170,0.4);
  }
  
  .hovereffect h2, .hovereffect img {
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  
  .hovereffect img {
    display: block;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  
  .hovereffect:hover img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  
  .hovereffect h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .hovereffect a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    margin: 50px 0 0 0;
    background-color: transparent;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    font-weight: normal;
    height: 85%;
    width: 85%;
    position: absolute;
    top: -20%;
    left: 8%;
    padding: 70px;
  }
  
  .hovereffect:hover a.info {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    background-color: rgba(0,0,0,0.4);
  }