@import url(https://fonts.googleapis.com/css?family=Raleway:400,500);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);

figure.snip0060 {
    font-family: 'Raleway', Arial, sans-serif;
    color: #fff;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #000000;
    text-align: left;
}

figure.snip0060 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

figure.snip0060 img {
    opacity: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

figure.snip0060 figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

figure.snip0060 figcaption>div {
    height: 50%;
    overflow: hidden;
    width: 100%;
    position: relative;
}

figure.snip0060 figcaption::before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 100%;
    height: 2px;
    content: '';
    opacity: 0;
    background-color: rgba(255, 255, 255, 0);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}

figure.snip0060 i,
figure.snip0060 p {
    margin: 0;
    opacity: 0;
    width: 100%;
}

figure.snip0060 i {
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    position: absolute;
    font-size: 28px;
    display: block;
    bottom: 0;
    left: 30px;
    background: #000000;
    -webkit-transform: translate3d(0%, 50%, 0);
    transform: translate3d(0%, 50%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.snip0060 p {
    padding: 10px 35px;
    font-weight: 500;
    -webkit-transform: translate3d(0%, -50%, 0);
    transform: translate3d(0%, -50%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.snip0060 a {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    color: #ffffff;
}

figure.snip0060.blue {
    background: #091b27;
}

figure.snip0060.blue i {
    background: #2980b9;
}

figure.snip0060.red {
    background: #2e0e0a;
}

figure.snip0060.red i {
    background: #c0392b;
}

figure.snip0060.yellow {
    background: #4d2909;
}

figure.snip0060.yellow i {
    background: #e67e22;
}

figure.snip0060.green {
    background: #061c10;
}

figure.snip0060.green i {
    background: #27ae60;
}

figure.snip0060.navy {
    background: #000000;
}

figure.snip0060.navy i {
    background: #34495e;
}

figure.snip0060:hover img,
figure.snip0060.hover img {
    opacity: 0.50;
}

figure.snip0060:hover figcaption i,
figure.snip0060.hover figcaption i,
figure.snip0060:hover figcaption p,
figure.snip0060.hover figcaption p,
figure.snip0060:hover figcaption h3,
figure.snip0060.hover figcaption h3 {
    -webkit-transform: translate3d(0%, 0%, 0);
    transform: translate3d(0%, 0%, 0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    opacity: 1;
}

figure.snip0060:hover figcaption::before,
figure.snip0060.hover figcaption::before {
    background: rgba(255, 255, 255, 0.8);
    left: 30px;
    opacity: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}