.footer {
    padding: 60px 0;
    width: 100%;
    background: #393E46;
    color: #fff;
    margin-top: auto;
}

.footer-title {
    position: relative;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid #4e4e4e;
}

.footer-title:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    background: #FFD369;
}

.footer-links a {
    padding: 10px 0;
    border-bottom: 1px solid #404040;
    color: #fff;
    display: block;
    transition: color 0.5s ease-in-out;
    text-decoration: none;
}

.footer-links a:hover {
    color: #FFD369;
}

.footer-social-links li {
    display: inline-block;
}

.footer-social-links a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 16px;
    float: left;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #404040;
    border-radius: 50px;
    text-align: center;
    color: #fff;

    background: #393E46;
}

.footer-social-links a:hover {
    background: #FFD369;
}

/* g. Footer bottom section */
.footer-bottom {
    width: 100%;
    padding: 25px 0;
    text-align: center;
    color: #fff;
    background: rgb(34, 40, 49);
}