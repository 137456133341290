.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #393e46;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #222831;
}

.btn-dashboard-del {
  position: absolute;
  top: 5%;
  left: 5%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #dd0000;
  color: white;
  font-size: 12px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.btn-dashboard-del:hover {
  background-color: #721616;
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}

.dashboard-footer-paragraph {
  cursor: pointer;
}

div.gallery_project_list {
  border: 1px solid #ccc;
  cursor: pointer;
}

div.gallery_project_list:hover {
  border: 1px solid #777;
}

div.gallery_project_list img {
  width: 100%;
  height: auto;
}

div.desc_project_list {
  padding: 15px;
  text-align: center;
}

.container_project_list {
  box-sizing: border-box;
  width: auto !important;
}

.responsive_project_list {
  padding: 0 6px;
  float: left;
  width: 24.99999%;
}

@media only screen and (max-width: 700px) {
  .responsive_project_list {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 500px) {
  .responsive_project_list {
    width: 100%;
  }
}

.cutterContainer {
  min-height: calc(100vh - 120px);
}
