.widget-menu {
  border: 1px solid #dcdddf;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.widget-menu li {
  color: #59595b;
  font-size: 16px;
  line-height: 24px;
  border-top: 1px dashed #dcdddf;
}

.widget-menu li b {
  display: block;
  font-weight: 500 !important;
  padding: 13px 25px;
}

.widget-menu li a {
  color: #59595b;
  padding: 13px 25px;
  display: block;
  font-weight: 300 !important;
  position: relative;
  z-index: 2;
  text-decoration: none;
}

.widget-menu li a:before {
  position: absolute;
  content: "";
  z-index: -1;
  left: 0;
  top: -1px;
  right: 0;
  opacity: 0;
  bottom: -1px;
  background: #FFD369;
  border-left: 0 solid #393E46;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.widget-menu li a:after {
  position: absolute;
  font-family: Font Awesome;
  content: "";
  right: 10px;
  top: 50%;
  display: block;
  width: 25px;
  line-height: 8px;
  margin-top: -4px;
  text-align: center !important;
  font-size: 12px;
  color: #fff;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  opacity: 0;
}

.widget-menu li a.active,
.widget-menu li a:hover {
  color: #fff;
}

.widget-menu li a.active:before,
.widget-menu li a:hover:before {
  border-left: 6px solid #091e2f;
  opacity: 1;
}

.widget-menu li a.active:after,
.widget-menu li a:hover:after {
  right: 0;
  opacity: 1;
}

.widget-menu li:first-child {
  border: none;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.detail {
  position: relative;
  border: 1px solid #dcdddf;
  border-bottom: 0;
  margin-bottom: 110px;
}

.detail>img {
  width: 100%;
}

.detail>div {
  padding: 20px;
}

.detail>div p {
  line-height: 40px;
}

.detail>div p b {
  font-weight: 600;
  text-align: start;
}

.detail>div .gallery {
  margin: 20px -5px 0 -5px;
}

.detail>div .gallery:before,
.detail>div .gallery:after {
  content: "";
  display: table;
  clear: both;
}

.detail>div .gallery li {
  width: 20%;
  float: left;
  padding: 5px;
}

.detail:before {
  position: absolute;
  z-index: -1;
  content: "";
  left: -1px;
  right: -1px;
  bottom: -50px;
  height: 90px;
  border-left: 1px solid #dcdddf;
  border-right: 1px solid #dcdddf;
  border-bottom: 7px solid #475663;
}

@media (max-width: 768px) {
  .detail>div .gallery li {
    width: 50%;
  }
}