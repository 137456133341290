.aboutusDetail {
    text-align: start;
    padding: 0px 0px 90px 0px;
}

.aboutusDetail h2 {
    font-size: 1.8em;
    line-height: 1.3;
    font-weight: 600;
    letter-spacing: -.05em;
    color: #222529;
}

.aboutusDetail p {
    margin: 0 0 1.25rem;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
}

.aboutusDetail .mx-wimg {
    max-width: 350px;
    margin: auto;
    border-radius: 30%;
}