.navbar-custom {
  height: 20px;
  background-color: whitesmoke;
  padding-bottom: 1%;
}


.navbarText {
  font-size: 12px;
}

.navbarSocials {
  color: #3b3b3b;
  transition: 0.3s;
}

.navbarSocials:hover {
  color: #FFD369;
}

@media only screen and (max-width: 600px) {
  .navbar-custom {
    height:40px;
  }
}